<template>
    <div class="two-column-list">
        <v-list-item
            three-line
            v-for="(completedTraining,index) in items" :key="index"
        >
            <v-list-item-content>
                <v-list-item-title>{{ $i18n.locale === 'en' ? completedTraining.training.name_en : completedTraining.training.name }}</v-list-item-title>
                <v-list-item-subtitle>
                    {{ $t('$training.daysUntilRenewal', { days: completedTraining.daysUntilRenewal }) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    {{ $t('$training.validUntil', { date: printDate(completedTraining.validUntil) }) }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: 'CompletedTrainings',
    props: {
        completedTrainings: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    computed: {
        items() {
            return this.completedTrainings;
        }
    },

    methods: {
        printDate(date) {
            return moment(date).format('DD.MM.YYYY');
        }
    }
}
</script>

<style lang="scss">
.two-column-list {
    column-count: 1;

    @media only screen and (min-width: 600px) {
        column-count: 2;
    }
}
</style>
