// import {getField, updateField} from "vuex-map-fields";
// import {ACTIONS} from "../action-types";
import makeCrudModule from "./crud";
import appointmentService from "../../services/appointment";
import {ACTIONS} from "../action-types";
import SubmissionError from "../../error/SubmissionError";

const appointmentModule = makeCrudModule({
  service: appointmentService
})

const getDefaultState = () => {
  return {
    ...appointmentModule.state,
    allIds: [],
    byId: {},

    form: {
      // visitor id
      visitorId: null,

      // contact details
      firstName: null,
      lastName: null,
      company: null,
      phoneNumber: null,
      email: null,
      dsgvo: false,

      // appointment
      expectedStartTime: null,
      expectedEndTime: null,
      location: null,
      space: null,
      visitAt: null,

      // trainings (list of iris)
      trainings: [],
      locationKey: null
    },

    // form: {
    //
    //   // visitor id
    //   visitorId: null,
    //
    //   // contact details
    //   firstName: 'Peter',
    //   lastName: 'Pan',
    //   company: 'Neverland',
    //   phoneNumber: '01245678910',
    //   email: 'peter.pan@never.land',
    //   dsgvo: true,
    //
    //   // appointment
    //   expectedStartTime: null,
    //   expectedEndTime: null,
    //   location: '/locations/ea276ab9-e2a0-4724-929b-6934205f4a46',
    //   space: '/spaces/8ceb35a8-5ed8-4beb-b61c-007eb9d3b36e',
    //   visitAt: null,
    //
    //   // trainings (list of iris)
    //   trainings: [],
    //
    //   locationKey: null
    // },

    requiredSpaceTrainings: [],
    // selectedSpaceId is set when user clicks next after space is selected on step 2.
    // in SpaceMixin.js we then used this changed for the computed property retrievedSpace
    selectedSpaceId: null
  }
}

const handleError = (commit, e) => {
  commit(ACTIONS.TOGGLE_LOADING);

  if (e instanceof SubmissionError) {
    commit(ACTIONS.SET_VIOLATIONS, e.errors);
    // eslint-disable-next-line
    commit(ACTIONS.SET_ERROR, e.errors._error);

    return;
  }

  // eslint-disable-next-line
  commit(ACTIONS.SET_ERROR, e.message);
};

export default {
  ...appointmentModule,
  actions: {
    ...appointmentModule.actions,

    registration: ({commit}, values) => {
      commit(ACTIONS.SET_ERROR, '');
      commit(ACTIONS.TOGGLE_LOADING);

      appointmentService
        .registration(values)
        .then(data => {
          commit(ACTIONS.TOGGLE_LOADING);
          commit(ACTIONS.SET_CREATED, data);
        })
        .catch(e => handleError(commit, e));
    },

    signIn: ({commit}, values) => {
      commit(ACTIONS.SET_ERROR, '');
      commit(ACTIONS.TOGGLE_LOADING);

      appointmentService
        .signIn(values)
        .then(data => {
          commit(ACTIONS.TOGGLE_LOADING);
          commit(ACTIONS.SET_CREATED, data);
        })
        .catch(e => handleError(commit, e));
    },

    resetState: ({commit}) => {
      commit(ACTIONS.RESET_STATE);
    },
  },
  mutations: {
    ...appointmentModule.mutations,

    [ACTIONS.RESET_STATE]: state => {
      Object.assign(state, getDefaultState());
    }
  },
  state: getDefaultState()
}
