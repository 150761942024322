import enVuetify from 'vuetify/es5/locale/en';

export default {
  $vuetify: enVuetify,
  countries: [],
  // CRUD
  $crud: {
    'Submit': 'Submit',
    'Reset': 'Reset',
    'Add': 'Add',
    'Delete': 'Delete',
    'Abort': 'Abort',
    'Edit': 'Edit',
    'Are you sure you want to delete this item?': 'Are you sure you want to delete this item?',
    'No results': 'No results',
    'Close': 'Close',
    'Cancel': 'Cancel',
    'Back': 'Back',
    'Prev': 'Previous',
    'Next': 'Next',
    'Proceed': 'Proceed',
    'Created': 'Created',
    'Updated': 'Updated',
    'Field': 'Field',
    'Value': 'Value',
    'Filters': 'Filters',
    'Filter': 'Filter',
    'Clear filter': 'Clear Filter',
    'Data unavailable': 'Data unavailable',
    'Loading': 'Loading...',
    'Deleted': 'Deleted',
    'Search': 'Search...',
    'Please type something': 'Please type something',
    'Type to start searching': 'Type to start searching...',
    'Records per page:': 'Records per page:',
    'Actions': 'Actions',
    'show': 'show',
    'Remove': 'Remove',
    'createdAt': 'Created at',
    'updatedAt': 'Updated at',
    'createdBy': 'Created by',
    'updatedBy': 'Updated By',
    'load more items': 'load more items',
  },
  // Validation
  $validation: {
    'Please, insert a value bigger than zero!': 'Please, insert a value bigger than zero!',
    'Field is required': 'Field is required',
    'Value is not valid': 'Value is not valid',
    'Please enter a valid email address': 'Please enter a valid email address',
    'This value should be numeric': 'This value should be numeric',
    'This value is too short': 'This value is too short',
    'This value should have at least {limit} characters': 'This value should have at least {limit} characters',
    'This value should have exactly {limit} characters': 'This value should have exactly {limit} characters',
    'Passwords must be identical': 'Passwords must be identical',
    'Please provide a secure password': 'Please provide a secure password (8 characters long containing upper/lower letter, digit, special character) ',
  },
  // Authentication
  'AuthenticationLogin': 'Login',
  'Invalid credentials.': 'Invalid credentials.',
  'Unauthorized.': 'Unauthorized. Please login!',
  $auth: {
    'Invalid credentials.': 'Invalid credentials.',
    'Unauthorized.': 'Unauthorized. Please login!',
  },
  $login: {
    'title': 'Login',
    'username': 'Username',
    'password': 'Password',
    'login': 'login',
    'logout': 'logout',
  },
  // Account
  'account': 'Account',
  'AccountOverview': 'My Account',
  $account: {
  },
  // User
  'users': 'Users',
  'UserList': 'Users',
  'UserShow': 'User details',
  'UserCreate': 'Create user',
  'UserUpdate': 'Update user',
  $user: {
    'username': 'Username',
    'email': 'E-Mail',
    'roles': 'Roles',
    'userRoles': 'User roles',
    'isAdmin': 'Administrator',
    'password': 'Password',
    'passwordRepeat': 'Repeat password',
    'isLocked': 'locked',
    'name': 'Name',
    'firstName': 'First name',
    'lastName': 'Last name',
    'initials': 'Initials',
    'phoneNumber': 'Phone',
    'mobileNumber': 'Mobile',
    'newUser': 'Create user',
  },
  // Home
  'Home': 'Home',
  $home: {

  },
  // Appointment
  'appointments': 'Appointments',
  'AppointmentList': 'Appointments',
  'AppointmentShow': 'Appointment details',
  'AppointmentCreate': 'Create appointment',
  'AppointmentUpdate': 'Update appointment',
  $registration: {
    'visitorId': 'Visitor-ID',
    'firstName': 'First name',
    'lastName': 'Last name',
    'company': 'Company',
    'phoneNumber': 'Phone',
    'email': 'E-Mail',
    'emailHint': 'Optional. We will send a confirmation with your Visitor-ID to the email address you provided.',
    'date': 'Date',
    'expectedStartAtDate': 'Expected date',
    'expectedStartAt': 'Expected start',
    'expectedEndAt': 'Expected end',
    'expectedStartTime': 'Expected start',
    'expectedEndTime': 'Expected end',
    'startAtDate': 'Date',
    'startAt': 'Start',
    'endAt': 'End',
    'startTime': 'Start',
    'endTime': 'End',
    'location': 'Location',
    'signInLocation': 'Sign-in location',
    'signOutLocation': 'Sign-out location',
    'space': 'Area',
    'spaceHint': 'Please select a location first',
    'visitAt': 'Visit at',
    'vest': 'Vest no.',
    'handover': 'Handover',
    'handoverRecord': 'Handover record',
    'handoverSignature': 'Signature',
    'handoverSignedAt': 'signed on',
    'handoverReturned': 'Returned',
    'handoverReturnedAt': 'Return on',
    'handoverNotes': 'Notes',
    'transponder': 'Transponder no.',
    'signIn': 'sign-in',
    'signOut': 'sign-out',
    'Are you sure you want to sign in this visit': 'Are you sure you want to sign in this visit?',
    $filter: {
      'expectedStartAtBeforeHint': 'Number of days in future',
      'startAtAfter': 'Start after',
      'startAtBefore': 'Start before',
      'existsStartAt': 'signed-in on site',
      'existsEndAt': 'signed-out',
    },
    'newAppointment': 'Create Appointment',
  },
  // Person
  'people': 'Visitors',
  'PersonList': 'Visitors',
  'PersonShow': 'Visitor details',
  'PersonCreate': 'Create visitor',
  'PersonUpdate': 'Update visitor',
  $person: {
    'visitorId': 'Visitor-ID',
    'firstName': 'First name',
    'lastName': 'Last name',
    'company': 'Company',
    'phoneNumber': 'Phone',
    'email': 'E-Mail',
  },
  // Host
  'hosts': 'Hosts',
  'HostList': 'Hosts',
  'HostShow': 'Host details',
  'HostCreate': 'Create host',
  'HostUpdate': 'Update host',
  $host: {
    'firstName': 'First name',
    'lastName': 'Last name',
    'email': 'E-Mail',
    'newHost': 'Create host',
  },
  // Onsite
  $onsite: {
    'title': 'Visitor Sign-In',
    'stepper': {
      'contactDetails': 'Contact info',
      'visit': 'Visit',
      'training': 'Training',
    },
    'signInIdle': {
      'title': 'Idle',
      'text': 'The sign-in process will quit soon due to inactivity.',
      'quit': 'quit',
      'continue': 'continue'
    },
  },
  // Public website
  $web: {
    'title': 'Visitor Sign-In',
    'stepper': {
      'contactDetails': 'Contact info',
      'visit': 'Visit',
      'training': 'Training',
    },
    $navigation: {
      'home': 'Home',
      'imprint': 'Imprint',
      'privacy': 'Privacy',
      'backToHome': 'back to home',
    },
  },
  // Welcome page
  $welcome: {
    'title': 'Welcome to LOGOSYS',
    'subtitle': 'Sign in or out as a visitor.',
    $signIn: {
      'title': 'Sign-In',
      'text': 'Sign in as a visitor before entering.',
      'signInInitial': 'Registration',
      'signInWithVisitorId': 'Sign-In with Visitor-ID',
    },
    $signOut: {
      'title': 'Sign-Out',
      'text': 'Log out after your visit.',
      'signOut': 'Sign-Out',
    }
  },
  // Welcome page (website)
  $welcomeWeb: {
    'subtitle': 'Register online and save time when signing in on site!',
    $registrationFirstTime: {
      'title': 'First time registration',
      'text': 'You are registering for a visit for the first time.'
    },
    $registration: {
      'title': 'Registration with Visitor-ID',
      'text1': 'You have already registered in the past and want to register with your Visitor-ID.',
      'text2': 'You do not have to repeat trainings that you have already completed.'
    },
  },
  // Training
  $training: {
    'training': 'Training',
    'contentInfoText': 'Please read the instructions and click Understood at the end.',
    'questionsInfoText': 'Please answer all questions and click on Submit at the end.',
    'understood': 'Understood',
    'submit': 'Submit',
    'trainingPassedTitle': 'Training passed',
    'trainingPassedText': 'You have passed all the required training for this area.',
    'submitAndComplete': 'Submit and complete',
    'completedTrainings': 'Completed trainings',
    'repeatDialog': {
      'toolbarTitle': 'Result',
      'title': 'Failed training.',
      'text': 'You did not answer enough questions correctly.',
      'buttonRepeat': 'Repeat training',
    },
    'daysUntilRenewal': 'valid for {days} days',
    'validUntil': 'renewal due on {date}',
  },
  // Completed page
  $completed: {
    'success': {
      'title': 'Sign-In complete',
      'text': 'Congratulation! You passed the training.'
    },
    'info1': {
      'title': 'Sign out with your Visitor-ID at the end of your visit',
      'text': 'If you sign-in for a visit with your Visitor-ID in the future, you will not have to enter contact details or training again.'
    },
    'info2': {
      'title': 'Sign out with QR code',
      'text': 'Open the camera app on your smartphone, hold the QR code in the camera\'s field of view and tap the link that appears. You can use the displayed QR code to sign out at the end of your visit.'
    },
    'completeSignInText': 'Finish your sign-in',
    'completeSignIn': 'Finish sign-in',
  },
  // Completed page on public website
  $completedWeb: {
    'success': {
      'title': 'Registration completed',
      'text': 'Congratulation! You passed the training.'
    },
    'info1': {
      'title': 'Please sign in and out with your Visitor-ID when visiting the site',
      'text': 'If you register for another visit with your Visitor-ID in the future, you will not have to enter contact details and training again.'
    },
    'info2': {
      'title': 'Sign in with QR code',
      'text': 'Scan the QR code shown above with your smartphone camera. A notification will appear on your smartphone. Tap the notification to open the link. You can use the displayed QR code to sign in on site.'
    },
  },
  // SignIn page
  $signIn: {
    'withQrCode': {
      'title': 'Sign in with QR code',
      'text': 'Hold the QR code in front of the camera on the right.',
    },
    'withVisitorId': {
      'title': 'Sign in with Visitor-ID',
    },
    'contactDetails': {
      'infoText1': 'After completing registration, you will receive a Visitor-ID that you can use to sign in and out on site.',
      'infoText2': 'If you sign in for a visit with your Visitor-ID in the future, you will not have to enter contact details and training again.'
    },
  },
  // SignOut page
  $signOut: {
    'signOutVisit': 'Sign out visit',
    'withQrCode': {
      'title': 'Sign out with QR code',
      'text': 'Hold the QR code in front of the camera on the right.',
    },
    'withVisitorId': {
      'title': 'Sign out with Visitor-ID',
    },
    'signOut': 'sign out',
    'success': 'Signed out successfully.',
    'thanksForVisit': 'Thank you for your visit!',
    'returnToHome': 'back to sign-in/-out',
  },
  // AnmerkungRisikobeurteilung component
  $riskManagement: {
    'title': 'Risk management note',
    'text1': 'As part of the new regulation of the Visitor Sign-In as a digital version, it must be ensured that even after completing the access process through registration, training and checking the knowledge of the visitor, no visitor is allowed alone in the area of the building defined as aviation security! (this applies to both locations with an air security area in Biebesheim)',
    'text2': 'The person must always be accompanied by a person whose reliability has been determined in accordance with §7 LuftSiG and who has passed training in accordance with 11.2.3.9 (LBA/WBT/D-00816/11239oF/M3).',
  },
  // PrivacyProtection component
  $privacyProtection: {
    'title': 'Information about data protection',
    'dataProtection': 'data protection',
    'acceptTerms': 'I have read the information on data protection and accept them.',
  },
  // Imprint page
  $imprint: {
    'title': 'Imprint',
  },
  // Privacy page
  $privacy: {
    'title': 'Privacy',
  },
  $error: {
    '403': {
      'title': 'Error 403: Forbidden',
      'text': 'Access denied.'
    },
    '404': {
      'title': 'Error 404: not found',
      'text': 'The requested page was not found.'
    },
    'noAppointmentFoundWithThatVisitorId': 'No sign-in found with this Visitor-ID. You may not be signed in. ',
    'visitorIdNotFound': 'This Visitor-ID does not exist.',
  },
};
