export const ACTIONS = {
  ADD: 'ADD',
  MERCURE_DELETED: 'MERCURE_DELETED',
  MERCURE_MESSAGE: 'MERCURE_MESSAGE',
  MERCURE_OPEN: 'MERCURE_OPEN',
  RESET_LIST: 'RESET_LIST',
  RESET_STATE: 'RESET_STATE',
  SET_CREATED: 'SET_CREATED',
  SET_DELETED: 'SET_DELETED',
  SET_ERROR: 'SET_ERROR',
  SET_SELECT_ITEMS: 'SET_SELECT_ITEMS',
  SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
  SET_UPDATED: 'SET_UPDATED',
  SET_VIEW: 'SET_VIEW',
  SET_VIOLATIONS: 'SET_VIOLATIONS',
  TOGGLE_LOADING: 'TOGGLE_LOADING'
};
