import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages_en from './locales/en';
import messages_de from './locales/de';
import countries from 'i18n-iso-countries';
import nationalities from 'i18n-nationality';

const messages = {
  en: messages_en,
  de: messages_de
};

for (const key of Object.keys(messages)) {
  countries.registerLocale( require(`i18n-iso-countries/langs/${key}.json`) );
  messages[key].countries = countries.getNames(key, {select: 'official'});

  nationalities.registerLocale( require(`i18n-nationality/langs/${key}.json`) );
  messages[key].nationalities = nationalities.getNames(key);
}

Vue.use(VueI18n);

// Use EN locale for other locales
const browserLocale = navigator.language.split('-')[0];

export default new VueI18n({
  locale: browserLocale !== 'de' ? 'en' : (process.env.VUE_APP_I18N_LOCALE || 'de'),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages
});
