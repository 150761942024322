import {getField, updateField} from "vuex-map-fields";
import {ACTIONS} from "../action-types";
import Vue from "vue";

const getDefaultState = () => {
  return {
    allIds: [],
    byId: {}
  }
};

export default {
  actions: {
    setTrainings: ({ commit }, completedTraining) => {
      commit(ACTIONS.RESET_LIST);

      completedTraining.forEach(item => {
        // transform data structure
        item['@id'] = item['training']['@id'];

        commit(ACTIONS.ADD, item);
      });
    },

    resetState: ({ commit }) => {
      commit(ACTIONS.RESET_STATE);
    }
  },
  getters: {
    find: state => id => {
      return state.byId[id];
    },

    getField,

    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    }
  },
  mutations: {
    updateField,

    [ACTIONS.RESET_LIST]: state => {
      Object.assign(state, {
        allIds: [],
        byId: {}
      });
    },

    [ACTIONS.ADD]: (state, item) => {
      Vue.set(state.byId, item['@id'], item);
      if (state.allIds.includes(item['@id'])) return;
      state.allIds.push(item['@id']);
    },

    [ACTIONS.RESET_STATE]: state => {
      Object.assign(state, getDefaultState());
    }
  },
  namespaced: true,
  state: getDefaultState()
}
