import SubmissionError from '../error/SubmissionError';
// import { normalize } from './hydra';
import Vue from 'vue';

const MIME_TYPE = 'application/ld+json';

export default function (id, options = {}) {
  // default value for options.baseURL is set in shared/plugins/http.js or apps/onsite/plugins/httpOnsite.js

  if ('undefined' === typeof options.headers) options.headers = {};

  if ('undefined' === options.headers['Accept']) {
    options.headers['Accept'] = MIME_TYPE;
  }

  if (
    'undefined' !== options.data &&
    !(options.data instanceof FormData) &&
    undefined === options.headers['Content-Type']
  ) {
    options.headers['Content-Type'] = MIME_TYPE;
  }

  options.url = id;

  return Vue.axios(options)
    .then(response => response.data)
    .catch(error => {
      if (error.response) {
        const data = error.response.data;

        const errorHydra =
          data['hydra:description'] ||
          data['hydra:title'] ||
          'An error occurred.';

        if (!data.violations) throw Error(errorHydra);

        let errors = {_error: errorHydra};
        data.violations.forEach(violation =>
          errors[violation.propertyPath]
            ? (errors[violation.propertyPath] +=
            '\n' + errors[violation.propertyPath])
            : (errors[violation.propertyPath] = violation.message)
        );

        throw new SubmissionError(errors);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        //throw new Error(error.response.statusText || 'An error occurred.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        //throw new Error(error.response.statusText || 'An error occurred.');
      }
    });
}
