<template>
    <div class="d-flex flex-column fill-height">
        <div v-if="requiredSpaceTrainings">
            <div
                v-for="(spaceTraining,index) in requiredSpaceTrainings" :key="index"
            >
                <div
                    v-show="trainingStep === index+1"
                >
                    <component
                        :is="componentForTraining(spaceTraining['training'])"
                        :value="spaceTraining['training']"
                        @prev="handlePrev(index+1)"
                        @next="handleNext(index+1)"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="trainingsCompleted"
            class="px-4 fill-height"
        >
            <div>
                <h2 class="mb-5 text-center">{{ $t('$training.trainingPassedTitle') }}</h2>

                <v-alert type="success" text dense border="left">
                    {{ $t('$training.trainingPassedText') }}
                </v-alert>
            </div>

            <div class="mt-auto py-2 text-center">
                <v-btn
                    color="primary"
                    x-large
                    @click="$emit('next')"
                >
                    {{ $t('$training.submitAndComplete') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiArrowRight} from "@mdi/js";
import ScrollTopMixin from "../../mixins/ScrollTopMixin";
// import TrainingContentPdf from "./TrainingContentPdf";
import TrainingContentHtml from "./TrainingContentHtml";
import TrainingQuestionary from "./TrainingQuestionary";
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

export default {
    name: 'Training',
    components: {
        TrainingContentHtml,
        // TrainingContentPdf,
        TrainingQuestionary,
        SvgIcon
    },
    mixins: [ScrollTopMixin],

    data() {
        return {
            icons: {mdiArrowRight},
            trainingStep: 1
        }
    },

    mounted() {
        this.trainingStep = 1;
    },

    computed: {
        ...mapFields('appointment', [
            'form.space',
            'form.trainings',
            'requiredSpaceTrainings',
            'selectedSpaceId'
        ]),

        ...mapGetters('space', {
            findSpace: 'find',
        }),

        trainingsCompleted() {
            return this.retrievedSpace && this.requiredSpaceTrainings.length <= 0;
        },

        retrievedSpace() {
            return this.findSpace(this.selectedSpaceId);
        },
    },

    methods: {
        handlePrev(trainingStep) {
            if(trainingStep <= 1) {
                this.$emit('prev');
            } else {
                this.trainingStep = this.trainingStep - 1;
            }
            this.scrollToTop();
        },
        handleNext(trainingStep) {
            if(trainingStep >= this.requiredSpaceTrainings.length) {
                this.$emit('next');
            } else {
                this.trainingStep = this.trainingStep + 1;
            }
            this.scrollToTop();
        },

        componentForTraining(training) {
            switch (training.type) {
                // case 'pdf': return 'TrainingContentPdf';
                case 'html': return 'TrainingContentHtml';
                case 'questionary': return 'TrainingQuestionary';
                default: return 'TrainingContentHtml';
            }
        },
    },
}
</script>
