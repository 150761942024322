<template>
    <div>
        <div class="my-5">
            <a href="#" @click.prevent="dsgvoDialog = true">
                {{ $t('$privacyProtection.title') }}
            </a>
        </div>

        <v-dialog
            v-model="dsgvoDialog"
            transition="dialog-bottom-transition"
            max-width="600"
            scrollable
        >
            <v-card>
                <v-toolbar
                    flat
                    dark
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="dsgvoDialog = false"
                    >
                        <SvgIcon type="mdi" :path="icons.mdiClose"/>
                    </v-btn>
                    <v-toolbar-title>
                        {{ $t('$privacyProtection.dataProtection') }}
                    </v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text style="height: 75vh" class="pt-2 pb-2">
                    <PrivacyProtectionContent/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        x-large
                        ripple
                        @click="dsgvoDialog = false"
                    >
                        {{ $t('$crud.Close') }}
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-checkbox
            v-model="dsgvoComputed"
            :label="$t('$privacyProtection.acceptTerms')"
            :error-messages="errors"
            style="margin: 0"
            class="my-5"
        >
        </v-checkbox>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiClose} from "@mdi/js";
import PrivacyProtectionContent from "../privacy/PrivacyProtectionContent";

export default {
    name: 'PrivacyProtectionForm',
    props: {
        dsgvo: Boolean,
        errors: {
            type: Array,
            default: () => []
        }
    },
    components: {PrivacyProtectionContent, SvgIcon},
    data() {
        return {
            dsgvoDialog: false,
            icons: {mdiClose},
        }
    },
    computed: {
        dsgvoComputed: {
            get() {
                return this.dsgvo;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>
