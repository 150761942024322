<template>
    <v-autocomplete
        ref="autocomplete"
        v-model="selected"
        return-object
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        item-value="@id"
        item-text="name"
        hide-no-data
        :hide-details="hideDetails"
        clearable
        :placeholder="$t('$crud.Type to start searching')"
        :label="$t('$registration.visitAt')"
        :prepend-icon="prependIcon"
        :dense="dense"
        :outlined="outlined"
        :error-messages="errorMessages"
    >
        <template v-slot:item="{ item }">
            <template>
                <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.email">{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </template>
        <template v-slot:append-item>
            <v-list-item v-if="!isLastPage">
                <v-list-item-content v-intersect.quiet="{ handler: loadMore,  }">
                    <v-list-item-title>{{ $t('$crud.Loading') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import hostService from '../../../shared/services/host';
import NotificationMixin from "../../mixins/NotificationMixin";
import { mdiAccountCircle } from '@mdi/js';

export default {
    name: 'HostSearchField',
    mixins: [NotificationMixin],
    props: {
        value: { required: true },
        errorMessages: { required: false },
        prependIcon: { default: mdiAccountCircle },
        dense: { default: false },
        outlined: { default: false },
        hideDetails: { default: false }
    },

    data: () => ({
        selected: null,
        entries: [],
        page: 1,
        itemsPerPage: 10,
        isLastPage: false,
        isLoading: false,
        search: null,
        searchTimeoutId: null,
        icons: {mdiAccountCircle}
    }),

    mounted() {
        this.load(true);
    },

    computed: {
        items () {
            return this.entries || [];
        },
    },

    watch: {
        selected(val) {
            // return id
            this.$emit('input', val && val['@id'] ? val['@id'] : null);
            // return object
            this.$emit('selecteditem', val);
        },

        search(val) {
            if(this.selected && !val) this.selected = null;

            if(this.selected && val === this.selected['name']) return;

            this.searchDebounce();
        },

        value(val) {
            if(!val) {
                this.selected = null;
                return;
            }

            if(val['@id']) val = val['@id'];

            let entry = this.entries.find(item => item['@id'] === val);

            if(entry) {
                this.selected = entry;
                return;
            }

            // retrieve if not found in list
            this.isLoading = false;

            hostService
                .find(val)
                .then(data => {
                    this.entries.push(data);
                    this.selected = data;
                })
                .catch(() => {
                    this.selected = null;
                })
                .finally(() => (this.isLoading = false));
        },
    },

    methods: {
        searchDebounce() {
            if(this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

            this.searchTimeoutId = setTimeout(() => {
                this.page = 1;
                this.load();
            }, 400);
        },

        loadMore() {
            this.page = this.page + 1;
            this.load(true);
        },

        load(append) {
            this.isLoading = true;
            this.isLastPage = true;

            const params = {
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }

            if(this.search) {
                params.search = String(this.search).trim();
            }

            hostService
                .findAll({
                    params: params
                })
                .then(data => {
                    if(append !== true) this.entries = [];

                    this.entries = [ ...this.entries, ...data['hydra:member'] ];

                    this.isLastPage = this.entries.length >= data['hydra:totalItems'];
                })
                .catch(() => {
                    this.showError('"Besuch bei"-Liste kann nicht geladen werden. ')
                })
                .finally(() => (this.isLoading = false));
        }
    }
}
</script>
