export default {
  methods: {
    scrollToTop(elementId) {
      elementId = elementId || 'app';

      this.$nextTick(() => {
        this.$vuetify.goTo(
          document.getElementById(elementId),
          {
            easing: 'linear',
            duration: 0
          }
        );
      });
    }
  }
}
