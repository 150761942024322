<template>
    <div class="d-flex flex-column fill-height">
        <div class="px-4">
            <h2>
                {{ $t('$training.training') }}: {{ $i18n.locale === 'en' ? training.name_en : training.name }}
            </h2>
        </div>

        <div class="px-4">
            <v-alert type="info" text border="left" dense>
                {{ $t('$training.contentInfoText') }}
            </v-alert>

            <div v-html="training.content_en" v-if="$i18n.locale === 'en'"></div>
            <div v-html="training.content" v-else></div>

            <div class="mt-auto d-flex py-2">
                <v-btn
                    x-large
                    @click="$emit('prev')"
                >
                    <SvgIcon type="mdi" :path="icons.mdiArrowLeft"/>
                    {{ $t('$crud.Back') }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="primary"
                    x-large
                    @click="$emit('next')"
                    :disabled="!training.content"
                >
                    {{ $t('$training.understood') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiArrowLeft} from "@mdi/js";
import TrainingMixin from "../../mixins/TrainingMixin";

export default {
    name: 'TrainingContentHtml',
    components: {SvgIcon},
    mixins: [TrainingMixin],
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            icons: {mdiArrowLeft}
        };
    },

    mounted() {
        this.loadTraining(this.value['@id'])
    },

    watch: {
        training(val) {
            if(val) {
                let toggleBtn = document.getElementsByClassName('training-readmore-toggle');
                toggleBtn.forEach(btn => {
                    btn.addEventListener("click", () => {
                        const readmore = document.getElementById('training-readmore-content');
                        readmore.classList.toggle("d-none");
                    })
                });
            }
        }
    }
}
</script>

<style lang="scss">
p {
    font-size: 0.9rem;
}

.training-collapsed-wrapper {
    position: relative;
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 1rem;

    .training-collapsed {
        position: relative;
        max-height: 500px;
        overflow: hidden;
    }

    .training-collapsed-readmore {
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .training-collapsed-gradient {
        height: 80%;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    }

    .training-collapsed-readmore-button {
        background: white;
        text-align: center;
        height: 20%;
    }
}

</style>
