<template>
    <v-snackbar
        v-model="show"
        :color="color"
        :multi-line="true"
        :timeout="timeout"
        bottom
        right
        vertical
    >
      <span style="white-space: pre-line">{{ text }}</span>
        <template v-if="subText">
            <p>{{ subText }}</p>
        </template>

        <template v-slot:action="{ attrs }">
            <v-btn
                dark
                text
                v-bind="attrs"
                @click.native="close"
            >
                <v-icon>{{ icons.mdiClose }}</v-icon> {{ $t('$crud.Close') }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import {mdiClose} from '@mdi/js';

export default {
    data: () => ({
        icons: {
            mdiClose
        }
    }),
    computed: {
        ...mapFields('notifications', ['color', 'show', 'subText', 'text', 'timeout'])
    },
    methods: {
        close() {
            this.show = false;
        }
    }
};
</script>
