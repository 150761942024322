export default {
  path: '/errors',
  name: 'errors',
  component: () => import('../components/error/Layout'),
  redirect: { name: 'Error404' },
  children: [{
    path: '403',
    name: 'Error403',
    component: () => import('../views/error/403'),
  }, {
    path: '*',
    name: 'Error404',
    component: () => import('../views/error/404'),
  }]
};
