<template>
    <div class="d-flex flex-column fill-height">
        <div class="px-4">
            <h2>
                {{ $t('$training.training') }}: {{ $i18n.locale === 'en' ? training.name_en : training.name }}
            </h2>
        </div>

        <div v-if="step === 'content'"
             class="px-4"
        >
            <v-alert type="info" text border="left" dense>
                {{ $t('$training.contentInfoText') }}
            </v-alert>

            <div v-html="training.content_en" v-if="$i18n.locale === 'en'"></div>
            <div v-html="training.content" v-else></div>

            <div class="mt-auto d-flex py-2">
                <v-btn
                    x-large
                    @click="$emit('prev')"
                >
                    <SvgIcon type="mdi" :path="icons.mdiArrowLeft"/>
                    {{ $t('$crud.Back') }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="primary"
                    x-large
                    @click="goToQuestionary"
                    :disabled="!training.content"
                >
                    {{ $t('$training.understood') }}
                </v-btn>
            </div>
        </div>

        <div v-else
             class="px-4"
        >
            <v-alert type="info" text border="left" dense>
                {{ $t('$training.questionsInfoText') }}
            </v-alert>

            <div class="questionary">
                <div
                    v-for="(question,questionNumber) in (this.$i18n.locale === 'en' ? this.training.questions_en : this.training.questions)" :key="questionNumber"
                    class="mt-3 question"
                >
                    <h4 v-html="question.question"/>

                    <v-radio-group
                        v-model="answers[questionNumber]"
                        class="mt-0"
                    >
                        <v-radio
                            v-for="(option,answer) in question.options" :key="answer"
                            :label="`${option}`"
                            :value="answer"
                        />
                    </v-radio-group>
                </div>
            </div>

            <div class="mt-auto d-flex py-2">
                <v-btn
                    x-large
                    @click="goToContent"
                >
                    <SvgIcon type="mdi" :path="icons.mdiArrowLeft"/>
                    {{ $t('$crud.Back') }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="primary"
                    x-large
                    @click="handleSubmit"
                    :disabled="!allAnswered"
                >
                    {{ $t('$training.submit') }}
                </v-btn>
            </div>
        </div>


        <v-dialog
            v-model="repeatDialog"
            transition="dialog-bottom-transition"
            max-width="600"
            scrollable
        >
            <v-card>
                <v-toolbar
                    flat
                    dark
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="repeatTraining"
                    >
                        <SvgIcon type="mdi" :path="icons.mdiClose"/>
                    </v-btn>
                    <v-toolbar-title>
                        {{ $t('$training.repeatDialog.toolbarTitle') }}
                    </v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text class="pt-2 pb-2">
                    <v-alert type="error">
                        <h3>{{ $t('$training.repeatDialog.title') }}</h3>

                        <p>{{ $t('$training.repeatDialog.text') }}</p>
                    </v-alert>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        x-large
                        ripple
                        @click="repeatTraining"
                    >
                        <SvgIcon type="mdi" :path="icons.mdiRestore"/>
                        {{ $t('$training.repeatDialog.buttonRepeat') }}
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiArrowLeft, mdiClose, mdiRestore} from "@mdi/js";
import ScrollTopMixin from "../../mixins/ScrollTopMixin";
import TrainingMixin from "../../mixins/TrainingMixin";

export default {
    name: 'TrainingQuestionary',
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    components: {SvgIcon},
    mixins: [ScrollTopMixin,TrainingMixin],
    data() {
        return {
            step: 'content',
            answers: {},
            allAnswered: false,
            repeatDialog: false,
            icons: {mdiArrowLeft,mdiClose,mdiRestore},
        }
    },

    mounted() {
        this.loadTraining(this.value['@id']);
    },

    watch: {
        answers: {
            deep: true,
            handler(val) {
                this.allAnswered = (Object.entries(val).length >= Object.entries(this.training.questions).length);
            }
        }
    },

    computed: {
        getQuestions() {
            return this.$i18n.locale === 'en' ? this.training.questions_en : this.training.questions;
        }
    },

    methods: {
        goToContent() {
            this.step = 'content';
            this.scrollToTop();
        },
        goToQuestionary() {
            this.step = 'questionary';
            this.scrollToTop();
        },

        repeatTraining() {
            this.answers = {};
            this.step = 'content';
            this.repeatDialog = false;
            this.scrollToTop();
        },

        checkScore(scoreRequired) {
            if(!(scoreRequired = parseInt(scoreRequired))) return;
            if(scoreRequired <= 0) return;

            const questions = this.training.questions;
            const answers = this.answers;
            let correctAnswers = 0;

            for(let key of Object.keys(answers)) {
                if (String(questions[key]['correct']) === String(answers[key])) {
                    correctAnswers++;
                }
            }

            return correctAnswers >= scoreRequired;
        },

        handleSubmit() {
            if(this.checkScore(this.training['scoreRequired'])) {
                this.$emit('next')
            } else {
                this.repeatDialog = true;
            }
        }
    },
}
</script>
