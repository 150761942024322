import makeService from './api';
import fetch from "../utils/fetch";

const endpoint = 'appointments';

export default {
  ...makeService(endpoint),

  exportCSV(params) {
    return fetch(`${endpoint}/export`, { method: 'GET', headers: { 'Content-Type': 'text/csv'}, params: params })
  },

  registration(payload) {
    return fetch(`${endpoint}/registration`, { method: 'POST', data: payload});
  },

  signIn(payload) {
    return fetch(`${endpoint}/sign-in`, { method: 'POST', data: payload});
  },
}
