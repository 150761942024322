import fetch from "../utils/fetch";

const endpoint = 'visitors';

export default {
  registration(payload) {
    return fetch( `${endpoint}/registration`, { method: 'POST', data: payload});
  },

  signIn(payload) {
    return fetch( `${endpoint}/sign-in`, { method: 'POST', data: payload});
  },

  signOut(payload) {
    return fetch(`${endpoint}/sign-out`, { method: 'POST', data: payload});
  },
};
