import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from "../i18n";

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2a438d',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
};

export default new Vuetify(opts)

