<template>
    <div class="anmerkung-container">
        <h3>{{ $t('$riskManagement.title') }}</h3>

        <p>{{ $t('$riskManagement.text1') }}</p>

        <p>{{ $t('$riskManagement.text2') }}</p>
    </div>
</template>

<script>
export default {
    name: 'AnmerkungRisikobeurteilung'
}
</script>

<style>
.anmerkung-container,
.anmerkung-container p {
    font-size: 0.8rem !important;
}
</style>
