
import makeCrudModule from "./crud";
import spacetrainingService from "../../services/spacetraining";
import {ACTIONS} from "../action-types";

const spacetrainingModule = makeCrudModule({
  service: spacetrainingService
})

const getDefaultState = () => {
  return {
    ...spacetrainingModule.state,
  }
}

export default {
  ...spacetrainingModule,
  actions: {
    ...spacetrainingModule.actions,
    loadMultiple: ({ commit }, ids) => {
      commit(ACTIONS.TOGGLE_LOADING);

      Promise
        .all(ids.map((id) => {
          return spacetrainingService.find(id);
        }))
        .then((items) => {
          items.map((item) => {
            commit(ACTIONS.ADD, item);
          });

          commit(ACTIONS.TOGGLE_LOADING);
        })
        .catch(console.error.bind(console));
    },
  },
  state: getDefaultState()
}
