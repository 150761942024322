import deVuetify from 'vuetify/es5/locale/de';

export default {
  $vuetify: deVuetify,
  countries: [],
  // CRUD
  $crud: {
    'Submit': 'Speichern',
    'Reset': 'Zurücksetzen',
    'Add': 'Erstellen',
    'Delete': 'Löschen',
    'Abort': 'Abbrechen',
    'Edit': 'Bearbeiten',
    'Are you sure you want to delete this item?': 'Möchten Sie diesen Eintrag wirklich löschen?',
    'No results': 'Keine Ergebnisse',
    'Close': 'Schließen',
    'Cancel': 'Abbrechen',
    'Back': 'Zurück',
    'Prev': 'Zurück',
    'Next': 'Weiter',
    'Proceed': 'Fortfahren',
    'Created': 'Eintrag erstellt',
    'Updated': 'Aktualisiert',
    'Field': 'Feld',
    'Value': 'Wert',
    'Filters': 'Filter',
    'Filter': 'Filtern',
    'Clear filter': 'Filter löschen',
    'Data unavailable': 'Daten nicht verfügbar',
    'Loading': 'Lade...',
    'Deleted': 'Gelöscht',
    'Search': 'Suchen...',
    'Please type something': 'Bitte etwas eingeben',
    'Type to start searching': 'Tippen Sie etwas ein, um zu suchen...',
    'Records per page:': 'Datensätze pro Seite:',
    'Actions': 'Aktionen',
    'show': 'anzeigen',
    'Remove': 'Entfernen',
    'createdAt': 'Erstellt am',
    'updatedAt': 'Aktualisiert am',
    'createdBy': 'Erstellt von',
    'updatedBy': 'Aktualisiert von',
    'load more items': 'weitere Einträge laden',
  },
  // Validation
  $validation: {
    'Please, insert a value bigger than zero!': 'Bitte geben Sie einen Wert größer als Null ein!',
    'Field is required': 'Feld ist erforderlich',
    'Value is not valid': 'Wert ist ungültig',
    'Please enter a valid email address': 'Bitte eine gültige E-Mail Adresse eingeben',
    'This value should be numeric': 'Dieser Wert sollte numerisch sein',
    'This value is too short': 'Dieser Wert ist zu kurz',
    'This value should have at least {limit} characters': 'Dieser Wert sollte mind. {limit} Zeichen lang sein',
    'This value should have exactly {limit} characters': 'Dieser Wert sollte genau {limit} Zeichen lang sein',
    'Passwords must be identical': 'Passwörter müssen identisch sein',
    'Please provide a secure password': 'Bitte geben Sie ein sicheres Passwort ein (8 Zeichen lang bestehend aus Groß-/Kleinbuchstabe, Ziffer, Sonderzeichen)',
  },
  // Authentication
  'AuthenticationLogin': 'Anmelden',
  'Invalid credentials.': 'Ungültige Anmeldedaten.',
  'Unauthorized.': 'Keine Berechtigung. Bitte anmelden!',
  $auth: {
    'Invalid credentials.': 'Ungültige Anmeldedaten.',
    'Unauthorized.': 'Keine Berechtigung. Bitte anmelden!',
  },
  $login: {
    'title': 'Anmelden',
    'username': 'Username',
    'password': 'Password',
    'login': 'anmelden',
    'logout': 'abmelden',
  },
  // Account
  'account': 'Benutzerkonto',
  'AccountOverview': 'Mein Benutzer',
  $account: {
  },
  // User
  'users': 'Benutzer',
  'UserList': 'Benutzerliste',
  'UserShow': 'Benutzerdetails',
  'UserCreate': 'Benutzer erstellen',
  'UserUpdate': 'Benutzer bearbeiten',
  $user: {
    'username': 'Benutzername',
    'email': 'E-Mail',
    'roles': 'Rollen',
    'userRoles': 'Benutzerrollen',
    'isAdmin': 'Administrator',
    'password': 'Passwort',
    'passwordRepeat': 'Passwort wiederholen',
    'isLocked': 'Gesperrt',
    'name': 'Name',
    'firstName': 'Vorname',
    'lastName': 'Nachname',
    'initials': 'Initiale',
    'phoneNumber': 'Festnetz',
    'mobileNumber': 'Mobil',
    'newUser': 'neuer Benutzer',
  },
  // Home
  'Home': 'Home',
  $home: {

  },
  // Appointment
  'appointments': 'Anmeldungen',
  'AppointmentList': 'Anmeldungen',
  'AppointmentShow': 'Details zu Anmeldung',
  'AppointmentCreate': 'Anmeldung erstellen',
  'AppointmentUpdate': 'Anmeldung bearbeiten',
  $registration: {
    'visitorId': 'Besucher-ID',
    'firstName': 'Vorname',
    'lastName': 'Nachname',
    'company': 'Firma',
    'phoneNumber': 'Telefon-Nr.',
    'email': 'E-Mail',
    'emailHint': 'Optional. Wir versenden eine Bestätigung mit Ihrer Besucher-ID an die angegebene E-Mail Adresse.',
    'date': 'Datum',
    'expectedStartAtDate': 'Datum geplant',
    'expectedStartAt': 'Start geplant',
    'expectedEndAt': 'Ende geplant',
    'expectedStartTime': 'Start geplant',
    'expectedEndTime': 'Ende geplant',
    'startAtDate': 'Datum',
    'startAt': 'Start',
    'endAt': 'Ende',
    'startTime': 'Start',
    'endTime': 'Ende',
    'location': 'Standort',
    'signInLocation': 'Standort Anm.',
    'signOutLocation': 'Standort Abm.',
    'space': 'Bereich',
    'spaceHint': 'Wählen Sie zuerst einen Standort aus',
    'visitAt': 'Besuch bei',
    'vest': 'Weste-Nr.',
    'handover': 'Übergabe',
    'handoverRecord': 'Übergabeprotokoll',
    'handoverSignature': 'Unterschrift',
    'handoverSignedAt': 'unterschrieben am',
    'handoverReturned': 'Rückgabe',
    'handoverReturnedAt': 'Rückgabe am',
    'handoverNotes': 'Bemerkungen',
    'transponder': 'Transponder-Nr.',
    'signIn': 'anmelden',
    'signOut': 'abmelden',
    'Are you sure you want to sign in this visit': 'Möchten Sie die Anmeldung für diesen Besuch wirklich tätigen?',
    $filter: {
      'expectedStartAtBeforeHint': 'Anzahl Tage in der Zukunft',
      'startAtAfter': 'Start after',
      'startAtBefore': 'Start before',
      'existsStartAt': 'vor Ort angemeldet',
      'existsEndAt': 'abgemeldet',
    },
    'newAppointment': 'neuer Termin',
  },
  // Person
  'people': 'Besucher',
  'PersonList': 'Besucher',
  'PersonShow': 'Details zur Person',
  'PersonCreate': 'Besucher anlegen',
  'PersonUpdate': 'Besucher bearbeiten',
  $person: {
    'visitorId': 'Besucher-ID',
    'firstName': 'Vorname',
    'lastName': 'Nachname',
    'company': 'Firma',
    'phoneNumber': 'Telefon-Nr.',
    'email': 'E-Mail',
  },
  // Host
  'hosts': 'Gastgeber',
  'HostList': 'Gastgeberliste',
  'HostShow': 'Gastgeberdetails',
  'HostCreate': 'Gastgeber erstellen',
  'HostUpdate': 'Gastgeber bearbeiten',
  $host: {
    'firstName': 'Vorname',
    'lastName': 'Nachname',
    'email': 'E-Mail',
    'newHost': 'neuer Gastgeber',
  },
  // Onsite
  $onsite: {
    'title': 'Besucher­anmeldung',
    'stepper': {
      'contactDetails': 'Kontaktdaten',
      'visit': 'Besuch',
      'training': 'Schulung',
    },
    'signInIdle': {
      'title': 'Inaktivität',
      'text': 'Der Vorgang wird in Kürze auf Grund von Inaktivität beendet.',
      'quit': 'Vorgang beenden',
      'continue': 'fortsetzen'
    },
  },
  // Public website
  $web: {
    'title': 'Besucher­anmeldung',
    'stepper': {
      'contactDetails': 'Kontaktdaten',
      'visit': 'Termin',
      'training': 'Schulung',
    },
    $navigation: {
      'home': 'Startseite',
      'imprint': 'Impressum',
      'privacy': 'Datenschutz',
      'backToHome': 'zurück zur Startseite',
    },
  },
  // Welcome page
  $welcome: {
    'title': 'Willkommen bei LOGOSYS',
    'subtitle': 'Melden Sie sich als Besucher an oder ab.',
    $signIn: {
      'title': 'Anmelden',
      'text': 'Melden Sie sich vor dem Betreten als Besucher an.',
      'signInInitial': 'Erstanmeldung',
      'signInWithVisitorId': 'anmelden mit Besucher-ID',
    },
    $signOut: {
      'title': 'Abmelden',
      'text': 'Melden Sie sich nach Ende Ihres Besuchs wieder ab.',
      'signOut': 'Abmelden',
    }
  },
  // Welcome page (website)
  $welcomeWeb: {
    'subtitle': 'Online voranmelden und Zeit sparen bei der Anmeldung vor Ort!',
    $registrationFirstTime: {
      'title': 'Voranmeldung mit Erstanmeldung',
      'text': 'Sie melden sich zum ersten Mal für einen Besuch an.'
    },
    $registration: {
      'title': 'Voranmeldung mit Besucher-ID',
      'text1': 'Sie haben sich in der Vergangenheit schon einmal angemeldet und möchten sich mit Ihrer Besucher-ID anmelden.',
      'text2': 'Bereits abgeschlossene Schulungen müssen Sie nicht erneut durchführen.'
    },
  },
  // Training page and components
  $training: {
    'training': 'Schulung',
    'contentInfoText': 'Bitte lesen Sie die Schulung durch und klicken Sie am Ende auf Verstanden.',
    'questionsInfoText': 'Bitte beantworten Sie alle Fragen und klicken Sie am Ende auf',
    'understood': 'Verstanden',
    'submit': 'Absenden',
    'trainingPassedTitle': 'Schulung bestanden',
    'trainingPassedText': 'Sie haben alle benötigten Schulungen für diesen Bereich bestanden.',
    'submitAndComplete': 'Absenden und abschließen',
    'completedTrainings': 'Abgeschlossene Schulungen',
    'repeatDialog': {
      'toolbarTitle': 'Ergebnis',
      'title': 'Schulung nicht bestanden.',
      'text': 'Sie haben nicht genügend Fragen richtig beantwortet. ',
      'buttonRepeat': 'Schulung wiederholen',
    },
    'daysUntilRenewal': 'noch {days} Tage gültig',
    'validUntil': 'Wiederholung am {date} fällig',
  },
  // Completed page
  $completed: {
    'success': {
      'title': 'Anmeldung abgeschlossen',
      'text': 'Glückwunsch! Sie haben die Schulung bestanden.'
    },
    'info1': {
      'title': 'Melden Sie sich mit Ihrer Besucher-ID am Ende Ihres Besuchs ab',
      'text': 'Wenn Sie sich in Zukunft für einen Besuch mit Ihrer Besucher-ID anmelden, müssen Sie die Eingabe der Kontaktdaten und Schulungen nicht wiederholen.'
    },
    'info2': {
      'title': 'Alternativ mit QR-Code abmelden',
      'text': 'Öffnen Sie die Kamera-App auf Ihrem Smartphone, halten Sie den QR-Code in das Sichtfeld der Kamera und tippen Sie auf den angezeigten Link. Mit dem angezeigten QR-Code können Sie sich am Ende Ihres Besuchs wieder abmelden.'
    },
    'completeSignInText': 'Schließen Sie die Anmeldung ab',
    'completeSignIn': 'Anmeldung abschließen',
  },
  // Completed page on public website
  $completedWeb: {
    'success': {
      'title': 'Voranmeldung abgeschlossen',
      'text': 'Glückwunsch! Sie haben die Schulung bestanden.'
    },
    'info1': {
      'title': 'Bitte melden Sie sich mit Ihrer Besucher-ID beim Besuch vor Ort an und ab',
      'text': 'Wenn Sie sich in Zukunft für einen Besuch mit Ihrer Besucher-ID anmelden, müssen Sie die Eingabe der Kontaktdaten und Schulungen nicht wiederholen.'
    },
    'info2': {
      'title': 'Mit QR-Code anmelden',
      'text': 'Scannen Sie mit Ihrer Smartphone-Kamera den oben angezeigten QR-Code. Es erscheint eine Benachrichtigung auf Ihrem Smartphone. Tippen Sie auf die Benachrichtigung, um den Link zu öffnen. Mit dem angezeigten QR-Code können Sie sich vor Ort anmelden.'
    },
  },
  // SignIn page
  $signIn: {
    'withQrCode': {
      'title': 'Mit QR-Code anmelden',
      'text': 'Halten Sie den QR-Code vor die Kamera an der rechten Seite.',
    },
    'withVisitorId': {
      'title': 'Mit Besucher-ID anmelden',
    },
    'contactDetails': {
      'infoText1': 'Sie erhalten nach Abschluss der Anmeldung eine Besucher-ID, mit der Sie sich vor Ort an- und abmelden können.',
      'infoText2': 'Wenn Sie sich in Zukunft für einen Besuch mit Ihrer Besucher-ID anmelden, müssen Sie die Eingabe der Kontaktdaten und Schulungen nicht wiederholen.',
    },
  },
  // SignOut page
  $signOut: {
    'signOutVisit': 'Besuch abmelden',
    'withQrCode': {
      'title': 'Mit QR-Code abmelden',
      'text': 'Halten Sie den QR-Code vor die Kamera an der rechten Seite.',
    },
    'withVisitorId': {
      'title': 'Mit Besucher-ID abmelden',
    },
    'signOut': 'abmelden',
    'success': 'Erfolgreich abgemeldet.',
    'thanksForVisit': 'Vielen Dank für Ihren Besuch!',
    'returnToHome': 'zurück zur An-/Abmeldung',
  },
  // AnmerkungRisikobeurteilung component
  $riskManagement: {
    'title': 'Anmerkung Risikobeurteilung',
    'text1': 'Im Rahmen der Neuregelung der Besucheranmeldung in einer digitalen Ausführung, ist sicherzustellen, dass auch nach Absolvierung des Zugangsprozesses durch Anmeldung, Schulung und Überprüfung der Kenntnisse des Besuchers kein Besucher allein in den als Luftsicherheit definierten Bereich des Gebäudes darf! (dies betrifft beide Standorte mit einem Luftsicherheitsbereich in Biebesheim)',
    'text2': 'Die Person muss grundsätzlich durch einen Person, deren Zuverlässigkeit nach §7 LuftSiG festgestellt und der eine Schulung nach 11.2.3.9 (LBA/WBT/D-00816/11239oF/M3) bestanden hat, begleitet werden.',
  },
  // PrivacyProtection component
  $privacyProtection: {
    'title': 'Hinweise zum Datenschutz',
    'dataProtection': 'Datenschutz',
    'acceptTerms': 'Ich habe die Hinweise zum Datenschutz gelesen und akzeptiere diese.',
  },
  // Imprint page
  $imprint: {
    'title': 'Impressum',
  },
  // Privacy page
  $privacy: {
    'title': 'Datenschutz',
  },
  $error: {
    '403': {
      'title': 'Fehler 403: Verboten',
      'text': 'Zugriff verweigert.'
    },
    '404': {
      'title': 'Fehler 404: nicht gefunden',
      'text': 'Der angeforderte Seite wurde nicht gefunden.'
    },
    'noAppointmentFoundWithThatVisitorId': 'Keine Anmeldung mit dieser Besucher-ID gefunden. Sie sind möglicherweise nicht angemeldet. ',
    'visitorIdNotFound': 'Diese Besucher-ID existiert nicht.',
  }
};
