
import makeCrudModule from "./crud";
import trainingService from "../../services/training";
import {ACTIONS} from "../action-types";

const trainingModule = makeCrudModule({
  service: trainingService
})

const getDefaultState = () => {
  return {
    ...trainingModule.state,
  }
}

export default {
  ...trainingModule,
  actions: {
    ...trainingModule.actions,
    loadMultiple: ({ commit }, ids) => {
      commit(ACTIONS.TOGGLE_LOADING);

      Promise
        .all(ids.map((id) => {
          return trainingService.find(id);
        }))
        .then((items) => {
          items.map((item) => {
            commit(ACTIONS.ADD, item);
          });

          commit(ACTIONS.TOGGLE_LOADING);
        })
        .catch(console.error.bind(console));
    },
  },
  state: getDefaultState()
}
