<template>
    <div>
        <h4>
            Information für unsere Kunden und Geschäftspartner gemäß Datenschutzgrundverordnung Artikel 13
        </h4>

        <br>

        <h4>Stand Mai 2018</h4>

        <p>Sehr geehrte Kunden und Geschäftspartner der LOGOSYS Logistik GmbH,</p>

        <p>
            mit den nachfolgenden Angaben möchten wir Sie darüber informieren, wozu wir personenbezogene Daten unserer Kunden und Geschäftspartner erheben und wie wir mit diesen Daten umgehen. Dieses Informationsblatt soll Sie auch auf ihre Rechte als Betroffene hinweisen.
        </p>
        <p>
            Die meisten dieser Informationen werden Ihnen bereits bekannt sein, Sie betreffende Angaben haben wir von Ihnen selbst erhalten. Sollten Sie darüber hinaus Fragen zu diesem Thema haben, wenden Sie sich bitte an Ihren Kundenberater oder kontaktieren Sie unseren Datenschutz&shy;beauftragten.
        </p>

        <h4>
            Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?
        </h4>
        <p>
            LOGOSYS Logistik GmbH<br>
            Otto-Röhm-Str. 69<br>
            D-64293 Darmstadt<br>
            Tel.: +49 6151 8147-0<br>
            Fax: +49 6151 8147-109<br>
            Kontaktdaten des Datenschutzbeauftragten: datenschutz@logosys.de<br>
        </p>

        <h4>
            Welche Kategorien personenbezogener Daten verarbeiten wir?
        </h4>
        <p>
            Unsere Vertragspartner sind Unternehmen. Im Kontakt mit unseren Kunden und Geschäftspartnern erheben wir Name und Kontaktdaten unserer Ansprechpartner sowie deren Firmenzugehörigkeit und Position bzw. Aufgabenbereich in der Firma. Kontakte und vertragliche Vereinbarungen mit unseren Kunden vermerken wir in unseren Auftrags-und Lagerverwaltungssystemen. Für die Abwicklung von Aufträgen werden Liefer-und Rechnungsanschriften, Korrespondenz und Fakturierungsdaten verarbeitet, die personenbezogene Daten enthalten können.
        </p>

        <h4>
            Wie lange werden diese Daten gespeichert?
        </h4>
        <p>
            Die Daten unserer Kunden und Geschäftspartner und deren Ansprechpartner bleiben gespeichert, so lange geschäftliche Beziehungen bestehen oder Sie Interesse an unseren Produkten bekunden. Für Daten zu vertraglichen Vereinbarungen und zur Fakturierung bestehen gesetzliche Aufbewahrungsfristen von 6 oder 10 Jahren.
        </p>

        <h4>
            Zu welchen Zwecken verarbeiten wir die Daten?
        </h4>
        <p>
            Wir verarbeiten die Daten unserer Kunden zur Beantwortung von Anfragen, zur Erstellung von Angeboten, zur Durchführung der vertraglichenvereinbarten Leistungen und zur Fakturierung.
        </p>

        <h4>
            Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?
        </h4>
        <p>
            Wir erheben und speichern Kundendaten ausschließlich auf der Grundlage vertraglicher oder vorvertraglicher Beziehungen (Art.6lit.b DSGVO).
        </p>

        <h4>
            Wer erhält die Daten?
        </h4>
        <p>
            Die für eigene Zwecke erhobenen Kundendaten geben wir nicht an Dritte weiter, soweit dies nicht zur Abwicklung der Aufträge erforderlich ist, zum Beispiel bei der Auslieferung unter Beteiligung Dritter.
        </p>

        <h4>
            Gibt es eine Verpflichtung, die Daten zur Verfügung zu stellen?
        </h4>

        <p>
            Falls sie keine vertragliche Beziehung mit uns eingehen, gibt es Ihrerseits keine Pflicht, uns Daten zur Verfügung zu stellen.
        </p>

        <h4>
            Gibt es eine automatisierte Entscheidungsfindung oder Profiling?
        </h4>
        <p>
            Wir führen auf der Basis ihrer personenbezogenen Daten keine automatisierten Entscheidungsfindung durch, die Ihnen gegenüber eine rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt (Art.22 DSGVO).
        </p>
        <p>
            Ihre Rechte als betroffene PersonSie haben nach dem Kapitel III der DSGVO das Recht auf Auskunft über die zu Ihrer Person gespeicherten Daten, das Recht auf Berichtigung falscher Daten, das Recht auf Löschung ihrer Daten oder auf eine Einschränkung der Verarbeitung, das Recht auf Widerspruch gegen die Verarbeitung, das Recht auf Datenportabilität und das Recht auf Rücknahme gegebener Einwilligungen, falls sie solche erteilt haben.
        </p>
        <p>
            Sie haben das Recht, sich bei der bayerischen oder einer anderen Datenschutz-Aufsichtsbehörde zu beschweren, wenn Sie der Auffassung sind, dass unser Unternehmen mit Ihren Daten unrechtmäßig oder unsachgemäß umgeht.
        </p>
    </div>
</template>

<script>
export default {
    name: "PrivacyProtectionContent"
}
</script>
