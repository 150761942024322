import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SpaceMixin',

  data() {
    return {
      selectedSpace: {},
    }
  },

  computed: {
    ...mapGetters('space', {
      findSpace: 'find',
      isLoadingSpace: 'isLoading',
    }),

    ...mapGetters('completedtraining', {
      findCompletedTraining: 'find',
      listCompletedTraining: 'list'
    }),

    retrievedSpace() {
      return this.findSpace(this.selectedSpaceId);
    },
  },

  methods: {
    ...mapActions('space', {
      retrieveSpace: 'load'
    }),

    getRequiredSpaceTrainingsForSpace(space) {
      if(!space || !space['spaceTrainings']) return [];

      return space['spaceTrainings'].filter(spaceTraining => {
        const completedTraining = this.findCompletedTraining(spaceTraining['training']['@id']);
        return !completedTraining || !completedTraining['valid'];
      });
    },
  },

  watch: {
    selectedSpaceId(val) {
      this.retrieveSpace(val);
    },

    retrievedSpace(val) {
      this.selectedSpace = { ...val };
      this.requiredSpaceTrainings = this.getRequiredSpaceTrainingsForSpace(this.selectedSpace);
      this.trainings = this.requiredSpaceTrainings.map(spaceTraining => spaceTraining['training']['@id']);
    },
  }
}
