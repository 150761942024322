import Vue from 'vue';

// Axios
import axios    from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios.create({
  baseURL: process.env.VUE_APP_API_ENTRYPOINT
}));
export default {
  root: process.env.VUE_APP_API_ENTRYPOINT
};
