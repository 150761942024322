<template>
    <v-form class="d-flex flex-column fill-height px-5">
        <div>
            <v-alert type="info" text border="left" dense>
                {{ $t('$signIn.contactDetails.infoText1') }}<br>
                <small>{{ $t('$signIn.contactDetails.infoText2') }}</small>
            </v-alert>
        </div>

        <div style="max-width: 500px" class="align-self-center">
            <v-text-field
                v-model="firstName"
                :label="$t('$registration.firstName')"
                :error-messages="firstNameErrors"
                maxlength="70"
            />

            <v-text-field
                v-model="lastName"
                :label="$t('$registration.lastName')"
                :error-messages="lastNameErrors"
                maxlength="70"
            />

            <v-text-field
                v-model="company"
                :label="$t('$registration.company')"
                :error-messages="companyErrors"
                maxlength="100"
            />

            <v-text-field
                v-model="phoneNumber"
                :label="$t('$registration.phoneNumber')"
                :error-messages="phoneNumberErrors"
                maxlength="50"
            />

            <v-text-field
                v-model="email"
                :label="$t('$registration.email')"
                :error-messages="emailErrors"
                :hint="$t('$registration.emailHint')"
                persistent-hint
                maxlength="200"
            />

            <PrivacyProtectionForm
                v-model="dsgvo"
                :errors="dsgvoErrors"
            />
        </div>

        <div class="mt-auto">
            <div class="d-flex pb-2">
                <v-btn
                    @click="handleAbort"
                    x-large
                    ripple
                >
                    {{ $t('$crud.Abort') }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="primary"
                    @click="handleNext"
                    x-large
                    ripple
                >
                    {{ $t('$crud.Next') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </div>
        </div>
    </v-form>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";
import {validationMixin} from 'vuelidate';
import {and, email, minLength, requiredIf} from "vuelidate/lib/validators";
import {mapFields} from "vuex-map-fields";
import PrivacyProtectionForm from "./PrivacyProtectionForm";

export default {
    name: 'ContactDetailsForm',
    mixins: [validationMixin],
    components: {PrivacyProtectionForm, SvgIcon},
    data() {
        return {
            icons: {mdiArrowLeft, mdiArrowRight}
        };
    },
    computed: {
        ...mapFields('appointment', [
            'form.firstName',
            'form.lastName',
            'form.company',
            'form.phoneNumber',
            'form.email',
            'form.dsgvo',
        ]),

        firstNameErrors() {
            return this.getErrorMessageReqAndLength('firstName');
        },

        lastNameErrors() {
            return this.getErrorMessageReqAndLength('lastName');
        },

        companyErrors() {
            return this.getErrorMessageReqAndLength('company');
        },

        phoneNumberErrors() {
            return this.getErrorMessageReqAndLength('phoneNumber');
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t('$validation.Please enter a valid email address'));
            return errors;
        },

        dsgvoErrors() {
            const errors = [];
            if (!this.$v.dsgvo.$dirty) return errors;
            !this.$v.dsgvo.valid && errors.push(this.$t('$validation.Field is required'));
            return errors;
        },
    },

    methods: {
        handleAbort() {
            this.$emit('abort');
        },

        handleNext() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.$emit('next');
            }
        },

        getErrorMessageReqAndLength(field) {
            const errors = [];
            if (!this.$v[field].$dirty) return errors;
            !this.$v[field].required && errors.push(this.$t('$validation.Field is required'));
            !this.$v[field].minLength && errors.push(this.$t('$validation.This value is too short'));
            return errors;
        },
    },

    validations: {
        firstName: {
            required: requiredIf(function() { return !this.useVisitorId }),
            minLength: minLength(2)
        },
        lastName: {
            required: requiredIf(function() { return !this.useVisitorId }),
            minLength: minLength(2)
        },
        company: {
            required: requiredIf(function() { return !this.useVisitorId }),
            minLength: minLength(2)
        },
        phoneNumber: {
            required: requiredIf(function() { return !this.useVisitorId }),
            minLength: minLength(8)
        },
        email: {
            email
        },
        dsgvo: {
            valid: and(
                requiredIf(function() { return !this.useVisitorId }),
                value => value === true
            )
        },
    }
};
</script>
