import {mapActions, mapGetters} from "vuex";

export default {
  name: 'TrainingMixin',

  data() {
    return {
      training: {},
    }
  },

  computed: {
    ...mapGetters('training', {
      find: 'find',
      isLoading: 'isLoading',
    }),

    retrieved() {
      return this.find(this.value['@id']);
    }
  },

  methods: {
    ...mapActions('training', {
      retrieve: 'load'
    }),

    loadTraining(id) {
      this.training = {};
      const training = this.find(id);

      if(training) {
        this.training = training;
        return;
      }

      this.retrieve(id);
    },
  },

  watch: {
    retrieved(val) {
      this.training = { ...val };
    }
  },
}
